import React from 'react';
import Image from 'next/image';

import styles from '../styles/Home.module.scss';

const Index = () => {
	return (
		<div className={styles.container}>
			<Image alt="Salobo Investments" src="/logo.png" width={427.85} height={79.75} />
		</div>
	);
};

export default Index;
